import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import { useAppState } from '@/hooks/useAppState';
import { useStableProps } from '@/hooks/useStable';
import useUserAuth from '@/hooks/useUserAuth';
import FakeBackgroundDesktop from '@/mabra/public/image/contentLock/fake-content-desktop.jpg';
import FakeBackgroundMobile from '@/mabra/public/image/contentLock/fake-content-mobile.jpg';
import { StandaloneContentLock } from 'base/components/ContentLock';
import NextImage from 'next/image';
import { useEffect, useMemo, useRef } from 'react';

export const MabraStandaloneContentLock: typeof StandaloneContentLock = ({ ...props }) => {
  const { logInUrl } = useUserAuth();
  const [{ type, device }] = useAppState();
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => ref.current?.showModal(), [ref]);

  const purchase = useMemo(
    () => (
      <>
        {type === 'front' && (
          <NextImage src={device === 'desktop' ? FakeBackgroundDesktop : FakeBackgroundMobile} alt="" />
        )}
        <dialog
          onClose={() => ref.current?.showModal()}
          onCancel={() => ref.current?.showModal()}
          ref={ref}
          className={
            'not-prose flex flex-col items-center gap-y-6 p-8 text-center shadow-[0px_0px_14px_rgba(0,0,0,0.25)] outline-none md:max-w-2xl [&::backdrop]:backdrop-blur'
          }
        >
          <h3 className="m-0 text-headline-sm sm:text-headline-lg">
            Det här innehållet är en del av MåBra:s utmaning 21 dagar
          </h3>
          <Button content={'Klicka här för att läsa mer'} link={{ href: '/21-dagar' }} />
          <Link
            href={logInUrl}
            content={'Redan medlem och deltar i vår utmaning? Klicka här för att logga in'}
            options={{ className: 'text-body-sm sm:text-body-md text-cherry-blossom-500 underline' }}
          />
        </dialog>
      </>
    ),
    [device, logInUrl, type],
  );

  const stableProps = useStableProps({ content: { purchase } }, props);

  return <StandaloneContentLock {...stableProps} />;
};
