/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "timer": {
    "size_default": "mx-auto mt-8"
  },
  "image": {
    "group_variant_default": "[&_picture]:left-0 relative",
    "size_default": "w-screen max-w-none left-[calc(-50vw+50%)]",
    "variant_default": "object-cover relative"
  },
  "button": {
    "extend": {
      "size_medium": "mx-auto md:mb-4 w-fit",
      "variant_default": "!block"
    }
  },
  "title_size_default": "text-headline-md md:text-headline-lg mb-6 md:mb-8",
  "title_colors_default": "",
  "title_variant_default": "text-center",
  "banner_header_size_default": "text-headline-md md:text-headline-lg mb-3 md:mb-4.5",
  "banner_header_colors_default": "",
  "banner_header_variant_default": "text-center",
  "banner_caption_size_default": "text-headline-xs md:text-headline-sm mb-6 md:mb-8",
  "banner_caption_colors_default": "",
  "banner_caption_variant_default": "text-center",
  "banner_contacts_size_default": "text-headline-2xs md:text-headline-xs",
  "banner_contacts_colors_default": "",
  "banner_contacts_variant_default": "text-center",
  "banner_size_default": "px-4 py-6 md:px-28 md:py-10 mb-6 md:mb-10 max-w-[786px] mx-auto",
  "banner_colors_default": "bg-yellow-sea-100 bg-[url(/image/course/confirmation-banner-background.svg)] bg-[length:100%_100%]",
  "banner_variant_default": "",
  "size_default": "p-6 pt-0",
  "colors_default": "",
  "variant_default": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Confirmation");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;