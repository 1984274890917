/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneMorePagesTitle as ResolvedStandaloneMorePagesTitle } from 'base/components/standalone/MorePagesTitle';

export const MorePagesTitle = ResolvedStandaloneMorePagesTitle;

export type MorePagesTitleProps = PropsFromComponent<typeof MorePagesTitle>;

export const MorePagesTitleWith = (extras: DynamicStandaloneExtras): typeof MorePagesTitle => {
    return function MorePagesTitle(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'MorePagesTitle');
        return <ResolvedStandaloneMorePagesTitle {...mergeProps({ options: { theme } }, props)} />;
    }
}