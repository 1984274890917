/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { MabraLabradorCourseDays as ResolvedLabradorCourseDays } from 'sites/mabra/components/labrador/Course/Days';

export const LabradorCourseDays: typeof ResolvedLabradorCourseDays = (props) => {
    return (
        <ErrorBoundary>
            <ResolvedLabradorCourseDays {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />
        </ErrorBoundary>
    );
}

export type LabradorCourseDaysProps = PropsFromComponent<typeof LabradorCourseDays>;
