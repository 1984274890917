/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneFront as ResolvedStandaloneFront } from 'sites/mabra/components/standalone/Front';

export const Front = ResolvedStandaloneFront;

export type FrontProps = PropsFromComponent<typeof Front>;

export const FrontWith = (extras: DynamicStandaloneExtras): typeof Front => {
    return function Front(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Front');
        return <ResolvedStandaloneFront {...mergeProps({ options: { theme } }, props)} />;
    }
}