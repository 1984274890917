import { useAppState } from '@/hooks/useAppState';
import { MabraStandaloneArticleTeaserDefault } from '@/mabra/components/ArticleTeaser/Default';
import { LabradorComponent } from '@/types/component';

import { getCategoryUrl } from 'base/components/ArticleTeaser/Default';

export const MabraLabradorArticleTeaserDefault: LabradorComponent = ({ type, data, meta, descendants }) => {
  const [{ device }] = useAppState();

  const { category, subtitle, kicker, title, publishedUrl, badgeData, displayProminentTagOnTeaser } = data ?? {};

  const labradorImage = descendants?.find((descendant) => descendant.type === 'labradorImage')?.data;

  const { imageUrl } = labradorImage ?? {};

  const {
    showKicker,
    isNativeAd,
    nativeAdLabel,
    teaserIndex,
    showSubtitle,
    showTitle,
    hasFullWidth,
    showCategory,
    articleType,
  } = meta ?? {};

  const src = imageUrl?.[device];

  const showCategoryOnDevice = showCategory?.[device];
  const showKickerOnDevice = showKicker?.[device];
  const showSubtitleOnDevice = showSubtitle?.[device];
  const showTitleOnDevice = showTitle?.[device];
  const hasFullWidthOnDevice = hasFullWidth?.[device];

  const isPriority = teaserIndex < 4;
  const isHorizontal = Boolean(meta.horizontal);

  const prominentTag = displayProminentTagOnTeaser ? data.prominentTag : undefined;

  const displayCategory = isNativeAd ? nativeAdLabel || category : category;

  return (
    <MabraStandaloneArticleTeaserDefault
      caption={showCategoryOnDevice && (prominentTag || displayCategory)}
      description={showSubtitleOnDevice && subtitle}
      kicker={showKickerOnDevice && kicker}
      headline={showTitleOnDevice && title}
      links={{
        article: { href: publishedUrl },
        caption: { href: getCategoryUrl(data, meta) },
      }}
      image={{
        src,
        options: { priority: isPriority },
      }}
      badge={badgeData}
      options={{
        ...data.options,
        variant: isHorizontal ? 'horizontal' : 'vertical',
      }}
      data-full-width={hasFullWidthOnDevice ? '' : undefined}
      articleType={articleType}
    />
  );
};
