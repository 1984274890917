/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneContentLock as ResolvedStandaloneContentLock } from 'sites/mabra/components/standalone/ContentLock';

export const ContentLock = ResolvedStandaloneContentLock;

export type ContentLockProps = PropsFromComponent<typeof ContentLock>;

export const ContentLockWith = (extras: DynamicStandaloneExtras): typeof ContentLock => {
    return function ContentLock(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ContentLock');
        return <ResolvedStandaloneContentLock {...mergeProps({ options: { theme } }, props)} />;
    }
}