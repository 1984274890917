import { ButtonWith } from '@/components/Button';
import { Icon } from '@/components/Icon';
import BackgroundImage from '@/mabra/public/image/course/banner-ellipse.svg';
import BannerImage from '@/mabra/public/image/course/mabra-banner-image.png';
import { useClassName, useTheme } from '@/styles/Banner';
import { StandaloneComponent } from '@/types/component';
import getUrl from '@/utils/getUrl';
import withTrackingAttributes from '@/utils/withTrackingAttributes';
import { StandaloneBanner, StandaloneBannerProps } from 'base/components/Banner';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import Tracking from 'base/components/TrackingComponent';
import NextImage from 'next/image';
import { useMemo } from 'react';

const GenericComponent = GenericComponentFactory({ useClassName });
const ContentHeadline = GenericComponent({ as: 'p', styles: { key: 'content_headline' } });
const ContentDescription = GenericComponent({ as: 'p', styles: { key: 'content_description' } });
const ContentLogo = GenericComponent({ as: 'picture', styles: { key: 'content_logo' } });

const Button = ButtonWith({ theme: { useTheme } });

const ctsUrl = getUrl('/21-dagar');

const ctsAttrs = withTrackingAttributes({
  object: 'promotion_banner',
  creative: 'promotion_banner',
  objectName: 'teaser',
  name: 'teaser',
  objectUrl: ctsUrl ? ctsUrl.toString() : '/21-dagar',
  id: ctsUrl ? ctsUrl.toString() : '/21-dagar',
  objectExtraInfo: [{ campaign: 'mabra_course_sep24' }],
  label: [{ campaign: 'mabra_course_sep24' }],
});

export const MabraStandaloneBanner: StandaloneComponent<StandaloneBannerProps> = (props) => {
  const backgroundImageMemo = useMemo(() => <BackgroundImage />, []);

  const contentMemo = useMemo(() => {
    const listElements = [
      'Nytt kostschema varje vecka.',
      'Receptbank med fler än 100 utvalda recept, granskade av vår kostrådgivare.',
      'Dagliga träningspass, speciellt framtagna av personliga tränaren Leila Söderholm. Enkla att anpassa efter din nivå, inga redskap behövs – och det tar inte mer än en kvart!',
      'Dagliga mejl med motivationstips, övningar och fördjupning.',
      'Tillgång till gemensam Facebook-grupp.',
    ];
    return (
      <>
        <ContentHeadline>Nya vanor på 21 dagar!</ContentHeadline>
        <ContentDescription>
          Vill du komma in i bra kost- och träningsvanor? Ge det 21 dagar! Anta vår roliga utmaning så ses vi i din
          mejlkorg 2 september.
        </ContentDescription>
        <ContentDescription>Du får:</ContentDescription>
        <ul>
          {listElements.map((text: string, index: number) => (
            <li key={index}>
              <ContentDescription>{text}</ContentDescription>
            </li>
          ))}
        </ul>
        <Button
          link={{ href: '/21-dagar' }}
          options={{ size: 'large', colors: 'primary' }}
          content={
            <>
              Anmäl dig här
              <Icon name="chevronRight" />
            </>
          }
        />
        <ContentLogo>
          <NextImage src={BannerImage} alt={'Banner Image'} />
        </ContentLogo>
      </>
    );
  }, []);

  return (
    <Tracking options={{ wrapChildren: true, ctsAttrs }}>
      <StandaloneBanner content={contentMemo} backgroundImage={backgroundImageMemo} {...props} />
    </Tracking>
  );
};
