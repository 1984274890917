import { KeesingGames } from '@/components/KeesingGames';
import { LabradorComponent } from '@/types/component';
import getConfig from 'modules/config';

export const LabradorKeesingGames: LabradorComponent = ({ type, data, meta, descendants, ...props }) => {
  const { customerId, scriptPath } = getConfig('keesingGames');

  if (!customerId || !scriptPath) return null;

  return <KeesingGames customerId={customerId} scriptPath={scriptPath} {...props} />;
};
