import { Image } from '@/components/Image';
import { OptionalLink } from '@/components/OptionalLink';
import { MabraStandaloneBadge } from '@/mabra/components/Badge';
import { StandaloneComponent } from '@/types/component';
import { StandaloneArticleTeaserBodytextProps } from 'base/components/ArticleTeaser/Bodytext';
import { ArticleTeaserBodytext } from 'base/components/ArticleTeaser/Bodytext/ArticleTeaserBodytext';
import { ArticleBadgeData } from 'lib/labrador/articleBadge';

interface MabraStandaloneArticleTeaserBodytextProps extends StandaloneArticleTeaserBodytextProps {
  badge?: ArticleBadgeData;
}

export const MabraStandaloneArticleTeaserBodytext: StandaloneComponent<MabraStandaloneArticleTeaserBodytextProps> = ({
  links,
  headline,
  category,
  description,
  footer,
  image,
  badge,
  options,
  ...props
}) => {
  const {
    $headline: headlineProps,
    $category: categoryProps,
    $description: descriptionProps,
    $footer: footerProps,
    $line: lineProps,
    $image: imageProps,
    $group: groupProps,
    variant,
    ...baseProps
  } = options || {};

  return (
    <div>
      {headline && (
        <ArticleTeaserBodytext.Line variant={variant} {...lineProps}>
          <ArticleTeaserBodytext.Headline variant={variant} {...headlineProps}>
            {headline}
          </ArticleTeaserBodytext.Headline>
        </ArticleTeaserBodytext.Line>
      )}

      <ArticleTeaserBodytext variant={variant} {...baseProps} {...props}>
        <OptionalLink
          {...links?.article}
          content={
            <ArticleTeaserBodytext.Image {...imageProps}>
              <Image src={image} alt={category || ''} />
            </ArticleTeaserBodytext.Image>
          }
        />
        <ArticleTeaserBodytext.Group variant={variant} {...groupProps}>
          {category && (
            <OptionalLink
              {...links?.category}
              content={
                <ArticleTeaserBodytext.Category variant={variant} {...categoryProps}>
                  {category}
                </ArticleTeaserBodytext.Category>
              }
            />
          )}
          {description && (
            <OptionalLink
              {...links?.article}
              content={
                <ArticleTeaserBodytext.Description variant={variant} {...descriptionProps}>
                  {description}
                </ArticleTeaserBodytext.Description>
              }
            />
          )}
          {footer && (
            <ArticleTeaserBodytext.Footer variant={variant} {...footerProps}>
              {footer}
            </ArticleTeaserBodytext.Footer>
          )}
        </ArticleTeaserBodytext.Group>
      </ArticleTeaserBodytext>
      {badge && <MabraStandaloneBadge data={badge} variant="bodytext" />}
    </div>
  );
};
