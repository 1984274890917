import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { KeesingGames } from 'base/components/KeesingGames/KeesingGames';
import Script from 'next/script';

export interface StandaloneKeesingGamesProps extends StandaloneComponentProps<typeof KeesingGames> {
  customerId: string;
  scriptPath: string;
}

export const StandaloneKeesingGames: StandaloneComponent<StandaloneKeesingGamesProps> = ({
  customerId,
  scriptPath,
  ...props
}) => {
  const baseURL = new URL(scriptPath);
  const mainBundleURL = new URL('main-bundle.js', baseURL).href;

  return (
    <KeesingGames {...props}>
      <KeesingGames.PuzzlePortal data-publicpath={scriptPath} data-customerid={customerId} id="puzzle-portal" />
      <Script async={true} type="text/javascript" src={mainBundleURL} />
    </KeesingGames>
  );
};
