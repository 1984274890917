import Feelgood from '@/mabra/public/image/coverbox/feelgood.svg';
import Halsa from '@/mabra/public/image/coverbox/halsa.svg';
import Medicin from '@/mabra/public/image/coverbox/medicin.svg';
import ModeOchSkonhet from '@/mabra/public/image/coverbox/mode-och-skonhet.svg';
import NyttigMat from '@/mabra/public/image/coverbox/nyttig-mat.svg';
import Psykologi from '@/mabra/public/image/coverbox/psykologi.svg';
import Relationer from '@/mabra/public/image/coverbox/relationer.svg';
import Reportage from '@/mabra/public/image/coverbox/reportage.svg';
import Resor from '@/mabra/public/image/coverbox/resor.svg';
import SenasteNytt from '@/mabra/public/image/coverbox/senaste-nytt.svg';
import Traning from '@/mabra/public/image/coverbox/traning.svg';
import Vikt from '@/mabra/public/image/coverbox/vikt.svg';
import { LabradorComponent } from '@/types/component';
import kebabToPascal from '@/utils/kebabToPascal';
import { LabradorCoverBox } from 'base/components/labrador/CoverBox';
import { ElementType } from 'react';

export const MabraCoverBoxIcons: Record<string, ElementType> = {
  Feelgood,
  Halsa,
  Medicin,
  ModeOchSkonhet,
  NyttigMat,
  Psykologi,
  Relationer,
  Reportage,
  Resor,
  SenasteNytt,
  Traning,
  Vikt,
};

export const MabraLabradorCoverBox: LabradorComponent = ({ type, meta, data, descendants }) => {
  const iconKey = kebabToPascal(meta.hostpath);
  const CoverBoxIcon = MabraCoverBoxIcons[iconKey];

  return (
    <LabradorCoverBox
      {...{ type, meta, data, descendants }}
      icon={CoverBoxIcon && <CoverBoxIcon className="w-17.5" />}
      options={{ colors: meta.hostpath }}
    />
  );
};
