import { useStableProps } from '@/hooks/useStable';
import { StandaloneComponent } from '@/types/component';
import { StandaloneSavedArticles, StandaloneSavedArticlesProps } from 'base/components/SavedArticles';

export const MabraStandaloneSavedArticles: StandaloneComponent<StandaloneSavedArticlesProps> = (props) => {
  const stableProps = useStableProps(
    {
      headline: 'Dina sparade artiklar',
      icon: { name: 'bin' },
    },
    props,
  );

  return <StandaloneSavedArticles {...stableProps} />;
};
