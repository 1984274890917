/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { StandaloneKeesingGames as ResolvedStandaloneKeesingGames } from 'base/components/standalone/KeesingGames';

export const KeesingGames = ResolvedStandaloneKeesingGames;

export type KeesingGamesProps = PropsFromComponent<typeof KeesingGames>;

export const KeesingGamesWith = (extras: DynamicStandaloneExtras): typeof KeesingGames => {
    return function KeesingGames(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'KeesingGames');
        return <ResolvedStandaloneKeesingGames {...mergeProps({ options: { theme } }, props)} />;
    }
}