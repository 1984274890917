/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneConfirmation as ResolvedStandaloneConfirmation } from 'sites/mabra/components/standalone/Confirmation';

export const Confirmation = ResolvedStandaloneConfirmation;

export type ConfirmationProps = PropsFromComponent<typeof Confirmation>;

export const ConfirmationWith = (extras: DynamicStandaloneExtras): typeof Confirmation => {
    return function Confirmation(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Confirmation');
        return <ResolvedStandaloneConfirmation {...mergeProps({ options: { theme } }, props)} />;
    }
}