/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneRecommendedArticles as ResolvedStandaloneRecommendedArticles } from 'sites/mabra/components/standalone/RecommendedArticles';

export const RecommendedArticles = ResolvedStandaloneRecommendedArticles;

export type RecommendedArticlesProps = PropsFromComponent<typeof RecommendedArticles>;

export const RecommendedArticlesWith = (extras: DynamicStandaloneExtras): typeof RecommendedArticles => {
    return function RecommendedArticles(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'RecommendedArticles');
        return <ResolvedStandaloneRecommendedArticles {...mergeProps({ options: { theme } }, props)} />;
    }
}