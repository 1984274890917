import { useAppState } from '@/hooks/useAppState';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import getConfig from 'modules/config';
import { ReactNode } from 'react';
import { isUndefined } from 'typesafe-utils';
import { MorePagesTitle } from './MorePagesTitle';

export interface StandaloneMorePagesTitleProps extends StandaloneComponentProps<typeof MorePagesTitle> {
  content?: ReactNode;
}

export const StandaloneMorePagesTitle: StandaloneComponent<StandaloneMorePagesTitleProps> = ({ content, ...props }) => {
  const [{ type }] = useAppState();
  const title = getConfig(`infinityScroll.${type}.title`);

  if (isUndefined(content) && isUndefined(title)) {
    return null;
  }

  return <MorePagesTitle {...props}>{content || title}</MorePagesTitle>;
};
