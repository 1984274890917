import { StandaloneComponent } from '@/types/component';
import { StandaloneMarkup, StandaloneMarkupProps } from 'base/components/Markup';
import useUserAuth from 'lib/hooks/useUserAuth';
import { useUserName } from 'lib/hooks/useUserName';

export const MabraStandaloneMarkup: StandaloneComponent<StandaloneMarkupProps> = ({ markup, ...props }) => {
  const { isUserLoggedIn } = useUserAuth();
  const { firstName } = useUserName();

  const showMemberMarkup = isUserLoggedIn && markup?.includes('id="mabra-member-welcome"');

  const memberMarkup = (
    <div className="flex flex-col items-center gap-2">
      <h2 className="text-headline-md">Hej {firstName}</h2>
      <div className="text-headline-xs">
        Vill du hantera din prenumeration?{' '}
        <a href="https://prenumerera.se/kundservice/" className="text-cherry-blossom-500 hover:underline">
          Det kan du göra här
        </a>
      </div>
    </div>
  );

  return showMemberMarkup ? memberMarkup : <StandaloneMarkup {...{ markup }} {...props} />;
};
