/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "slide": {
    "image": {
      "size_default": "min-w-25 sm:min-w-37.5"
    }
  },
  "swiper": {},
  "disclaimer_line_variant_default": "text-center border-b",
  "disclaimer_line_colors_default": "border-gray-500",
  "disclaimer_line_size_default": "h-3.5 mb-4 md:mb-6",
  "disclaimer_colors_default": "text-black bg-white",
  "disclaimer_size_default": "text-headline-2xs px-3 md:px-3",
  "disclaimer_variant_default": "uppercase",
  "title_variant_default": "",
  "title_colors_default": "",
  "title_size_default": "text-headline-xs pt-1 md:pt-3 md:pb-0",
  "price_variant_default": "after:content-[\":-\"] !font-bold",
  "price_colors_default": "",
  "price_size_default": "text-headline-xs pb-1 md:pb-3",
  "colors_default": "",
  "size_default": "w-screen ml-[-50vw] md:w-full md:ml-0",
  "variant_default": "relative left-1/2 md:left-0"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "AffiliateGallery");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;