import { Link } from '@/components/Link';
import { useStableProps } from '@/hooks/useStable';
import { StandaloneComponent } from '@/types/component';
import { StandaloneConfirmation, StandaloneConfirmationProps } from 'base/components/Confirmation';
import getConfig from 'modules/config';

export const MabraStandaloneConfirmation: StandaloneComponent<StandaloneConfirmationProps> = (props) => {
  const { closeTime } = getConfig('payments') ?? {};

  const stableConfirmation = useStableProps(
    {
      title: '21 dagar kost & träning',
      banner: {
        header: 'Tack för din anmälan – snart kör vi!',
        caption:
          'Vi har skickat ett bekräftelsemejl till e-postadressen som du angav. Där hittar du mer info om utmaningen och hur du kan förbereda dig inför starten 2 september.',
        contacts: (
          <>
            Om du inte har fått ett bekräftelsemejl inom 72 timmar kontakta kundservice{' '}
            <Link
              href="mailto:21-dagar@mabra.com"
              content="21-dagar@mabra.com"
              options={{ className: 'text-cherry-blossom-500 font-bold hover:underline' }}
            />
          </>
        ),
      },
      timer: { title: 'Kursen börjar om:', finishTime: closeTime },
      button: { content: 'Läs det senaste från MåBra', link: { href: '/' } },
    },
    props,
  );

  return <StandaloneConfirmation {...stableConfirmation} />;
};
