/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "slide": {
    "caption_colors_default": ""
  },
  "swiper": {
    "arrow_colors_default": "text-cannon-pink-600",
    "navigation_item_colors_default": "bg-cannon-pink-200 data-[active=true]:bg-cannon-pink-700",
    "title_colors_default": "",
    "title_size_default": "text-headline !font-bold mx-6 pb-3 md:pb-6",
    "title_variant_default": "md:text-center"
  },
  "colors_default": "bg-cannon-pink-100 wings-cannon-pink-100",
  "size_default": "py-6 w-screen -ml-[calc(50vw-50%)] md:w-full md:ml-0",
  "variant_default": "relative wings"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "BlogSlider");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;