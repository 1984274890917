/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "content_colors_default": "bg-white sm:shadow-yellow-sea-300",
  "content_size_default": "w-full pt-24 px-4 pb-1 container-col-md-4:px-2 container-col-md-4:pt-24 container-col-md-4:pb-4 sm:px-12 sm:pt-22 sm:pb-4 sm:container-col-md-4:px-12 sm:container-col-md-4:pt-22 sm:container-col-md-4:pb-4 md:pt-20 md:px-8 md:pb-4 md:container-col-md-4:px-16 md:container-col-md-4:pt-22 md:container-col-md-4:pb-6 lg:container-col-md-4:px-22 lg:container-col-md-4:pt-12 lg:container-col-md-4:pb-6 xl:pt-20 xl:px-8 xl:pb-4 xl:container-col-md-4:px-22 xl:container-col-md-4:pt-14 xl:container-col-md-4:pb-8 [&_ul]:ml-8 [&_li]:mt-2",
  "content_variant_default": "relative flex flex-col sm:shadow-[0px_0px_0px_2px] [&_ul]:list-disc",
  "backgroundImage_colors_default": "text-yellow-sea-400",
  "backgroundImage_size_default": "w-[1216px] lg:w-full",
  "backgroundImage_variant_default": "absolute bottom-0 right-0",
  "colors_default": "bg-yellow-sea-100",
  "size_default": "w-full p-2.5 sm:p-10 sm:container-col-md-4:p-6 md:p-16 md:py-8 md:container-col-md-2:px-24 md:container-col-sm-12:px-48 md:container-col-md-4:py-10 lg:container-col-md-4:px-58 lg:container-col-md-4:py-10 xl:px-24 xl:py-6 xl:container-col-md-4:px-68 xl:container-col-md-4:py-14",
  "variant_default": "flex flex-col relative overflow-hidden not-prose",
  "button": {
    "extend": {
      "size_large": "mt-5 sm:mt-8 sm:mb-0",
      "variant_default": "self-center"
    }
  },
  "content_headline_colors_default": "",
  "content_headline_size_default": "text-headline-md !font-bold",
  "content_headline_variant_default": "sm:text-center",
  "content_description_colors_default": "",
  "content_description_size_default": "text-preheadline-md mb-0 mt-4 [li_&]:mt-0",
  "content_description_variant_default": "",
  "content_logo_size_default": "h-16 w-20 sm:h-18 sm:w-22",
  "content_logo_variant_default": "absolute top-3 left-3 sm:top-2 sm:left-2"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Banner");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;