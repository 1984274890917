import { tw } from '@/utils/tw';

const KeesingGames = tw.theme({
  slots: {
    base: '',
    puzzlePortal: '',
  },
});

export default KeesingGames;
