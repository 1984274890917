/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "button": {},
  "tag": {
    "size_text": "text-preheadline capitalize"
  },
  "empty_size_default": "mt-3 gap-3 col-span-4 text-preheadline",
  "empty_colors_default": "",
  "empty_variant_default": "flex flex-col",
  "headline_variant_default": "inline",
  "headline_colors_default": "",
  "headline_size_default": "text-headline-sm",
  "line_variant_default": "",
  "line_colors_default": "",
  "line_size_default": "",
  "button_variant_default": "",
  "button_colors_default": "",
  "button_size_default": "mx-auto mt-6 w-fit",
  "icon_variant_default": "cursor-pointer",
  "icon_colors_default": "text-gray-400 hover:text-gray-600",
  "icon_size_default": "",
  "article_caption_variant_default": "",
  "article_caption_colors_default": "",
  "article_caption_size_default": "pt-1.5 text-headline-sm",
  "article_description_variant_default": "line-clamp-3",
  "article_description_colors_default": "",
  "article_description_size_default": "pt-1.5 sm:pt-2 text-headline-2xs sm:text-headline-xs",
  "article_header_variant_default": "flex justify-between items-center",
  "article_header_colors_default": "",
  "article_header_size_default": "pt-3",
  "article_link_variant_default": "",
  "article_link_colors_default": "",
  "article_link_size_default": "",
  "article_image_variant_default": "",
  "article_image_colors_default": "",
  "article_image_size_default": "",
  "article_variant_default": "",
  "article_colors_default": "",
  "article_size_default": "",
  "variant_default": "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4",
  "colors_default": "",
  "size_default": "gap-6"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "SavedArticles");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;