/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneBadge as ResolvedStandaloneBadge } from 'sites/mabra/components/standalone/Badge';

export const Badge = ResolvedStandaloneBadge;

export type BadgeProps = PropsFromComponent<typeof Badge>;

export const BadgeWith = (extras: DynamicStandaloneExtras): typeof Badge => {
    return function Badge(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Badge');
        return <ResolvedStandaloneBadge {...mergeProps({ options: { theme } }, props)} />;
    }
}