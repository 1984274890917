/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneSaveArticleButton as ResolvedStandaloneSaveArticleButton } from 'sites/mabra/components/standalone/SaveArticleButton';

export const SaveArticleButton = ResolvedStandaloneSaveArticleButton;

export type SaveArticleButtonProps = PropsFromComponent<typeof SaveArticleButton>;

export const SaveArticleButtonWith = (extras: DynamicStandaloneExtras): typeof SaveArticleButton => {
    return function SaveArticleButton(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'SaveArticleButton');
        return <ResolvedStandaloneSaveArticleButton {...mergeProps({ options: { theme } }, props)} />;
    }
}