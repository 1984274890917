/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneSavedArticles as ResolvedStandaloneSavedArticles } from 'sites/mabra/components/standalone/SavedArticles';

export const SavedArticles = ResolvedStandaloneSavedArticles;

export type SavedArticlesProps = PropsFromComponent<typeof SavedArticles>;

export const SavedArticlesWith = (extras: DynamicStandaloneExtras): typeof SavedArticles => {
    return function SavedArticles(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'SavedArticles');
        return <ResolvedStandaloneSavedArticles {...mergeProps({ options: { theme } }, props)} />;
    }
}