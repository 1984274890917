import { JwVideoTeaser } from '@/components/JwVideo/Teaser';
import { MabraStandaloneBadge } from '@/mabra/components/Badge';
import { componentTheme } from '@/styles/JwVideoTeaser';
import { LabradorComponent } from '@/types/component';

export const MabraLabradorJwVideoTeaser: LabradorComponent = ({ type, meta, data }) => {
  const { videoID, thumbnail, isFrontPage, teaserLink, title, hintText, stillImage, badge, badgeData, prominentTag } =
    data;

  const componentProps = {
    mediaId: videoID,
    teaserLink,
    thumbnail,
    title,
    hintText,
    isStillImage: stillImage,
    badge,
    tag: prominentTag,
    ...(isFrontPage
      ? {
          options: {
            theme: componentTheme('front_page'),
            variant: 'vertical',
            $content: {
              $hint: {
                colors: 'secondary',
              },
              $tag: {
                theme: componentTheme('tag'),
              },
            },
          },
        }
      : {
          options: {
            colors: 'primary',
            variant: 'horizontal',
            $content: {
              $tag: {
                theme: componentTheme('tag'),
              },
            },
          },
        }),
  };

  return (
    <>
      <JwVideoTeaser {...componentProps} />
      {badge && <MabraStandaloneBadge data={badgeData} variant="bodytext" />}
    </>
  );
};
