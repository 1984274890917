import { StandaloneComponent } from '@/types/component';
import cn from '@/utils/cn';
import { mergeProps } from '@/utils/merge';
import { StandaloneArticleHeader, StandaloneArticleHeaderProps } from 'base/components/ArticleHeader';
import { ArticleBadgeData } from 'lib/labrador/articleBadge';

export const MabraStandaloneArticleHeader: StandaloneComponent<
  StandaloneArticleHeaderProps & { badge?: ArticleBadgeData }
> = (props) => {
  return (
    <StandaloneArticleHeader
      {...mergeProps(
        {
          withSaveArticle: true,
          options: {
            fill: true,
            priority: true,
            $caption: {
              $byline: {
                className: cn(
                  'text-headline-2xs',
                  '!text-gray-600',
                  'not-italic',
                  'sm:text-right',
                  props.badge ? 'mt-1' : '-mt-2',
                  'sm:-mt-2',
                ),
              },
              $text: { className: 'h-0 !mb-0' },
            },
          },
        },
        props,
      )}
    />
  );
};
